var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", { attrs: { title: _vm.title, items: _vm.items } }),
      _c("maintenance-orders-table", {
        attrs: { reloadOrders: _vm.reloadOrders },
        on: {
          selectedOrders: _vm.selectedOrders,
          reloadOrdersDone: function($event) {
            _vm.reloadOrders = $event
          }
        }
      }),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-lg-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _c("div", { staticClass: "card-title" }),
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v(" " + _vm._s(_vm.maintenanceOrderData.ref) + " ")
                ]),
                _c(
                  "b-tabs",
                  {
                    attrs: {
                      justified: "",
                      "nav-class": "nav-tabs-custom",
                      "content-class": "p-3 text-muted"
                    }
                  },
                  [
                    _c(
                      "b-tab",
                      {
                        attrs: { active: "" },
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [_c("i", { staticClass: "fas fa-home" })]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [_vm._v("Détails")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-xl-12" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-6" }, [
                                _c(
                                  "div",
                                  { staticClass: "table-responsive mb-0" },
                                  [
                                    _c("table", { staticClass: "table" }, [
                                      _c("tbody", [
                                        _c("tr", [
                                          _c("th", [_vm._v("Demandeur :")]),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.maintenanceOrderData
                                                    .maintenanceRequestData
                                                    .requestedBy
                                                ) +
                                                " "
                                            )
                                          ])
                                        ]),
                                        _c("tr", [
                                          _c("th", [
                                            _vm._v("Type de maintenance :")
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.maintenanceOrderData
                                                    .maintenanceRequestData.type
                                                ) +
                                                " "
                                            )
                                          ])
                                        ]),
                                        _c("tr", [
                                          _c("th", [
                                            _vm._v("Lieu de maintenance :")
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.maintenanceOrderData
                                                    .maintenanceRequestData
                                                    .location
                                                ) +
                                                " / " +
                                                _vm._s(
                                                  _vm.maintenanceOrderData
                                                    .maintenanceRequestData
                                                    .locationRec
                                                ) +
                                                " "
                                            )
                                          ])
                                        ]),
                                        _c("tr", [
                                          _c("th", [_vm._v("Urgence :")]),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.maintenanceOrderData
                                                    .maintenanceRequestData
                                                    .urgency
                                                ) +
                                                " "
                                            )
                                          ])
                                        ]),
                                        _c("tr", [
                                          _c("th", [_vm._v("Source :")]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.maintenanceOrderData
                                                  .maintenanceRequestData.ref
                                              ) + " "
                                            )
                                          ])
                                        ]),
                                        _vm.maintenanceOrderData
                                          .maintenanceRequestData.ov_file
                                          ? _c("tr", [
                                              _c("th", [
                                                _vm._v("Attachement :")
                                              ]),
                                              _c("td", [
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: {
                                                      href:
                                                        _vm.maintenanceOrderData
                                                          .maintenanceRequestData
                                                          .ov_file.fullPath,
                                                      target: "_blank"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.maintenanceOrderData
                                                          .maintenanceRequestData
                                                          .ov_file.name
                                                      ) + " "
                                                    ),
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-download"
                                                    })
                                                  ]
                                                )
                                              ])
                                            ])
                                          : _vm._e()
                                      ])
                                    ])
                                  ]
                                )
                              ]),
                              _c("div", { staticClass: "col-md-6" }, [
                                _c(
                                  "div",
                                  { staticClass: "table-responsive mb-0" },
                                  [
                                    _c("table", { staticClass: "table" }, [
                                      _c("tbody", [
                                        _c("tr", [
                                          _c("th", [
                                            _vm._v("Date de création :")
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.maintenanceOrderData
                                                    .createdAt
                                                ) +
                                                " "
                                            )
                                          ])
                                        ]),
                                        _c("tr", [
                                          _c("th", [
                                            _vm._v("Ordre créer par :")
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.maintenanceOrderData
                                                    .createdBy
                                                ) +
                                                " "
                                            )
                                          ])
                                        ]),
                                        _c("tr", [
                                          _c("th", [
                                            _vm._v(
                                              "Date début d'intervention :"
                                            )
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.maintenanceOrderData
                                                    .start_date
                                                ) +
                                                " "
                                            )
                                          ])
                                        ]),
                                        _c("tr", [
                                          _c("th", [
                                            _vm._v(
                                              "Date fin d'intervention prévue :"
                                            )
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.maintenanceOrderData
                                                    .planned_end_date
                                                ) +
                                                " " +
                                                _vm._s(
                                                  _vm.maintenanceOrderData
                                                    .planned_end_times
                                                ) +
                                                " "
                                            )
                                          ])
                                        ]),
                                        _c("tr", [
                                          _c("th", [_vm._v("Etat :")]),
                                          _c("td", [
                                            _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.maintenanceOrderData
                                                    .status
                                                )
                                              }
                                            })
                                          ])
                                        ]),
                                        _c("tr", [
                                          _c("th", [
                                            _vm._v("Etat d'exécution :")
                                          ]),
                                          _c("td", [
                                            _c("span", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.maintenanceOrderData
                                                    .executionStatus
                                                )
                                              }
                                            })
                                          ])
                                        ])
                                      ])
                                    ])
                                  ]
                                )
                              ])
                            ]),
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-md-12" }, [
                                _c("h4", { staticClass: "card-title mb-4" }, [
                                  _vm._v("Détails")
                                ]),
                                _c(
                                  "p",
                                  { attrs: { lass: "text-muted mb-4" } },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.maintenanceOrderData.description
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            ])
                          ])
                        ])
                      ]
                    ),
                    _c(
                      "b-tab",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.maintenanceOrderData.execution_type ==
                                "supplier" &&
                              _vm.maintenanceOrderData.extension,
                            expression:
                              'maintenanceOrderData.execution_type == "supplier" && maintenanceOrderData.extension'
                          }
                        ],
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [_c("i", { staticClass: "far fa-user" })]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [_vm._v("Prolongations")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-12" }, [
                            _c("div", { staticClass: "table-responsive" }, [
                              _c(
                                "table",
                                { staticClass: "table mb-0 table-bordered" },
                                [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", [_vm._v("début")]),
                                      _c("th", [_vm._v("fin")]),
                                      _c("th", [_vm._v("Status ")]),
                                      _c("th", [_vm._v("Commentaires ")]),
                                      _c("th", [_vm._v("Action ")])
                                    ])
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.maintenanceOrderData.extension,
                                      function(extension) {
                                        return _c("tr", { key: extension.id }, [
                                          _c("td", [
                                            _vm._v(_vm._s(extension.startDate))
                                          ]),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(extension.endDate) +
                                                " " +
                                                _vm._s(extension.endTime)
                                            )
                                          ]),
                                          _c("td", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                extension.status
                                              )
                                            }
                                          }),
                                          _c("td", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                extension.comments
                                              )
                                            }
                                          }),
                                          _c("td", [
                                            extension.statusPlain == "draft"
                                              ? _c("span", [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-primary",
                                                      attrs: { type: "button" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.changeExtensionStatus(
                                                            "approved",
                                                            extension.id
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fas fa-check  text-white "
                                                      })
                                                    ]
                                                  ),
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-danger",
                                                      attrs: { type: "button" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.changeExtensionStatus(
                                                            "rejected",
                                                            extension.id
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fas fa-times  text-white "
                                                      })
                                                    ]
                                                  )
                                                ])
                                              : _vm._e()
                                          ])
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                ]
                              )
                            ])
                          ])
                        ])
                      ]
                    ),
                    _c(
                      "b-tab",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [_c("i", { staticClass: "far fa-user" })]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [_vm._v("Intervenants")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-12" }, [
                            _c("div", { staticClass: "table-responsive" }, [
                              _c(
                                "table",
                                { staticClass: "table mb-0 table-bordered" },
                                [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", [_vm._v("Matricule")]),
                                      _c("th", [_vm._v("Nom")]),
                                      _c("th", [_vm._v("Prénom")]),
                                      _c("th", [_vm._v("Fonction")])
                                    ])
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.maintenanceOrderData
                                        .assignedEmployees,
                                      function(employee) {
                                        return _c("tr", { key: employee.id }, [
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(employee.employee_number)
                                            )
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(employee.first_name))
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(employee.last_name))
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(employee.postTitle))
                                          ])
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                ]
                              )
                            ])
                          ])
                        ])
                      ]
                    ),
                    _c(
                      "b-tab",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [_c("i", { staticClass: "far fa-envelope" })]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [_vm._v("Evaluation")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-md-12" }, [
                            _c("div", { staticClass: "table-responsive" }, [
                              _c(
                                "table",
                                { staticClass: "table mb-0 table-bordered" },
                                [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", [_vm._v("Code Barre")]),
                                      _c("th", [_vm._v("Désignation")]),
                                      _c("th", [_vm._v("Quantité")])
                                    ])
                                  ]),
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.maintenanceOrderData.assignedProducts,
                                      function(product) {
                                        return _c("tr", { key: product.id }, [
                                          _c("td", [
                                            _vm._v(_vm._s(product.codebare))
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(product.name))
                                          ]),
                                          _c("td", [
                                            _vm._v(_vm._s(product.qty))
                                          ])
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                ]
                              )
                            ])
                          ])
                        ])
                      ]
                    ),
                    _c(
                      "b-tab",
                      {
                        scopedSlots: _vm._u([
                          {
                            key: "title",
                            fn: function() {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "d-inline-block d-sm-none" },
                                  [_c("i", { staticClass: "fas fa-cog" })]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "d-none d-sm-inline-block" },
                                  [_vm._v("Rapport")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c("div", { staticClass: "row" }, [
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.maintenanceOrderData.evaluate_comments
                              )
                            }
                          })
                        ])
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }